<template>
  <div
    class="form-builder__tel"
    :class="schema.styleClasses || ''"
  >
    <div :class="`q-field q-validation-component row no-wrap items-start full-width q-field--standout ${$q.dark.isActive ? 'q-field--dark text-white' : ''}`">
      <div class="q-field__inner relative-position col self-stretch column justify-center">
        <div :class="`q-field__control relative-position row no-wrap ${bg}`">
          <vue-tel-input
            :value="schema.value"
            v-bind="bindProps"
            :disabled="schema.disabled"
            class="custom-input items-center fit"
            :label="schema.label"
            @change="handleChange"
            @focus="handleFocus"
            @blur="handleFocusOut"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'vue3-tel-input/dist/vue3-tel-input.css'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'TelephoneInput',
  components: {
    VueTelInput: defineAsyncComponent(() => import('vue3-tel-input').then(m => m.VueTelInput))
  },
  props: {
    schema: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      bindProps: {
        wrapperClasses: 'width-full',
        inputClasses: 'form-control width-full',
        defaultCountry:'BG',
        enabledCountryCode: false,
        inputOptions: {
          showDialCode: true
        }
      },
      isFocused: false
    }
  },
  computed: {
    bg () {
      return this.isFocused
        ? 'bg-teal text-white'
        : ''
    }
  },
  methods: {
    handleChange (e) {
      typeof this.schema.onChange === 'function' && this.schema.onChange(e.target.value)
    },
    handleFocus () {
      this.isFocused = true
    },
    handleFocusOut () {
      this.isFocused = false
    }
  }
}
</script>
